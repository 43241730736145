/* stylelint-disable selector-id-pattern */
#modalOverlay {
    width: 100%;
    height: 100%;
    z-index: 65556; /* places the modal overlay between the main page and the modal dialog */
    background-color: var(--color-black);
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    margin: 0;
    padding: 0;
}
/* stylelint-enable selector-id-pattern */
.accessible-modal {
    margin-left: auto;
    margin-right: auto;
    z-index: 65557;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    bottom: 0;
    right: 0;

    &.open {
        display: block;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    &__container {
        position: relative;
        background-color: var(--color-white);
        padding: 20px;
        margin: 133px auto 0;
        width: 627px;
        max-width: 100%;
        border-radius: 0 10px;
        box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.25);
    }

    &__container--large {
        width: 705px;
    }

    &__container--small {
        width: 474px;
    }

    &__close-btn {
        position: absolute;
        top: 20px;
        right: 23px;
        display: block;
        padding: 0;
        width: 20px;
        height: 20px;
        background: url("../icons/close.svg");
        background-size: 14px;
        background-position: center;
        background-repeat: no-repeat;
    }
}

#warning-modal,
#category-warning-modal {
    display: none; 
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding-bottom: 60px;

    .modal-content {
        padding: 20px;
        width: 40%;
        min-width: 300px;
        margin: 10% auto;
        text-align: center;
        border-radius: 5px;
        background-color: var(--color-white);
    }

    .modal-buttons {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        gap: 10px;
    }

    .modal-buttons button {
        padding: 8px 12px;
        cursor: pointer;
    }
}

#timeout-warning, #whistleblower-timeout-warning{
    display: none; 
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    &.hidden{
        display: none;
    }

    &.visible {
        display: block;
    }

    .modal-content {
        background: var(--color-white);
        padding: 20px;
        width: 350px;
        margin: 20% auto;
        text-align: center;
        border-radius: 5px;
    }

    .modal-buttons {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        gap: 10px;
    }

    .modal-buttons button {
        padding: 8px 12px;
        cursor: pointer;
    }
}
