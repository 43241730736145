/*
==========================================================================
   #Colors
========================================================================== */
:root {
    // Basic colors
    --color-white: #ffffff;
    --color-gray: #696969;
    --color-black: #191d1f;
    --color-black-2: #303436;
    --color-1: #e5e5e5;
    --color-2: #11062f;
    --color-3: #9a9a9a;

    // Focus color
    --color-focus: #1799d2;

    // Link colors
    --color-link: #043394;
    --color-link-hover: #1799d2;
    --color-link-tertiary: #323232;

    // Error page colors
    --color-error-h1: #000000;
    --color-error-h2: #000000;

    // System Error Messages colors
    --color-system-alert-msg-text: #ffffff;
    --color-system-alert-msg-bg: #d3e4f5;
    --color-system-alert-msg-border: #4b88c7;
    --color-system-alert-error-text: #f30045;
    --color-system-alert-error-bg: #f30045;
    --color-system-alert-error-border: #f30045;
    --color-system-alert-notice-text: #bb8b00;
    --color-system-alert-notice-bg: #fffaed;
    --color-system-alert-notice-border: #ecb925;
    --color-system-alert-success-bg: #33ad83;

    // hr
    --color-hr: #505a69;
}
