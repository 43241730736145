.list-block {
    --list-bg: #f5f5f5;
    --container-bg: transparent;
    --list-text: #919191;
    --color-title: #003594;
    --col-count: 1;

    @media (max-width: 575.98px) {
        --list-text: #555555;

    }

    .base-template__optuspage & {
        --col-count: 2;
        --list-bg: #ffffff;
        --container-bg: #f5f5f5;

        @media (max-width: 575.98px) {
            --col-count: 1;
        }

        .container {
            max-width: 1300px;

            @media (min-width: 576px) {
                padding-left: 2.5rem;
                padding-right: 2.5rem;
            }
        }

        ul {
            @media (min-width: 850px) {
                max-width: 100%;
            }
    
            @media (min-width: 1150px) {
                max-width: 100%;
            }
        }
    }

    background-color: var(--list-bg);

    .container {
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: var(--container-bg);

        @media (min-width: 576px) {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
        }

        @media (min-width: 850px) {
            padding-left: 3rem;
            padding-right: 3rem;
        }
    }

    &__title {
        margin-top: 0;
        font-size: 28px;
        font-weight: 100;
        color: var(--color-title);

        @media (max-width: 849.98px) {
            font-size: 24px;
            font-weight: 400;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin-left: auto;
        margin-right: auto;

        display: grid;
        grid-template-columns: repeat(var(--col-count), 1fr);

        @media (min-width: 850px) {
            max-width: 58.3333333333%;
        }

        @media (min-width: 1150px) {
            max-width: 41.6666666667%;
        }
    }

    .list-item {
        position: relative;
        padding-left: 60px;

        &:not(:last-child) {
            margin-bottom: 2.5rem;
        }

        &__title {
            font-size: 30px;
            font-weight: 100;
            margin-top: 0;
            margin-bottom: 8px;

            @media (max-width: 849.98px) {
                font-size: 26px;
                font-weight: 400;
            }
        }

        p {
            color: var(--list-text);
            margin-top: 0;
        }

        img {
            width: 36px;
            height: auto;
            object-fit: contain;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}