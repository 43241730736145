.landing-page,
.organisation-page-header,
.footer-org {
    &.base-template__optuspage {
        .btn {
            --color-success: #fecc08;
            --color-success-text: #000000;
            --color-success-hover: #000000;
            --color-success-text-hover: #fecc08;
            --color-success-border-hover: #000000;
            --filter: brightness(0) saturate(100%) invert(0%) sepia(4%) saturate(18%) hue-rotate(149deg) brightness(93%) contrast(104%);
            --filter-hover: brightness(0) saturate(100%) invert(72%) sepia(98%) saturate(860%) hue-rotate(354deg) brightness(104%) contrast(99%);
            --color-btn-arrow: #ffffff;
            --color-btn-arrow-hover: #d9d9d9;
            --color-outline-white: #003594;
            --color-outline-white-hover: #ffffff;
        }
    }
    .btn {
        --color-success: #62bb46;
        --color-success-text: #ffffff;
        --color-success-text-hover: #ffffff;
        --color-success-hover: #53a03b;
        --color-success-border-hover: #4e9737;
        --color-outline-white: #ffffff;
        --color-outline-white-hover: #212529;
        --color-btn-arrow: #d0df59;
        --color-btn-arrow-hover: #ffffff;

        display: inline-block;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        background-color: transparent;
        padding: 0.8rem 2rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 2px;
        text-decoration: none;
        text-transform: initial;
        font-family: PKFGlobalSans;
        border: 0;

        &:focus {
            outline: 0;
        }

        &-lg {
            padding: 0.5rem 1rem;
            font-size: 1.25rem;
            line-height: 1.5;
        }

        &-success {
            color: var(--color-success-text);
            background-color: var(--color-success);
            border-color: var(--color-success);
            padding: 1rem 1.5rem 1rem;

            &:hover,
            &:focus {
                background-color: var(--color-success-hover);
                color: var(--color-success-text-hover);
                border-color: var(--color-success-border-hover);

                &::before {
                    filter: var(--filter-hover);
                }
            }

            &::before {
                content: "";
                display: inline-block;
                vertical-align: middle;
                background-image: url("../img/success.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                width: 24px;
                height: 24px;
                margin-right: 1rem;
                filter: var(--filter);
            }
        }

        &-outline-white {
            color: var(--color-outline-white);
            border: 1px solid var(--color-outline-white);
            background-color: transparent;
            &:hover,
            &:focus {
                color: var(--color-outline-white-hover);
                background-color: var(--color-outline-white);
                border-color: var(--color-outline-white);
            }
        }

        &-arrow {
            font-size: 14px;
            padding: 0;
            color: var(--color-btn-arrow);

            &:hover,
            &:focus {
                color: var(--color-btn-arrow-hover);
            }

            &::before {
                content: "➔";
                display: inline-block;
                margin-right: 6px;
            }

            &.text-lime {
                color: var(--color-btn-arrow);
                &:hover,
                &:focus {
                    color: var(--color-btn-arrow-hover);
                }
            }
        }

        &-selected-number {
            margin-bottom: .5rem;
            font-weight: 400;
            line-height: 1.2;
            padding: 0;

            &::before {
                content: "";
                display: inline-block;
                vertical-align: middle;
                background-image: url("../img/phone.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                width: 18px;
                height: 18px;
                margin-right: 1rem;
            }
        }

        &.btn-primary,
        &.btn-secondary {
            text-transform: uppercase;
        }
    }
}
