.multi-step-block {
    --color-step-1: #355daa;
    --color-title: #003594;

    .even {
        --color-step-1: #E2E96D;
    }

    .base-template__optuspage & {
        --color-step-1: #fecc08;

        .even {
            --color-step-1: #33b4be;
        }
    }

    &__title {
        margin-top: 0;
        margin-bottom: 2.5rem ;
        font-size: 28px;
        font-weight: 100;
        color: var(--color-title);

        @media (min-width: 850px) {
                margin-bottom: 3rem;
        }

        @media (max-width: 849.98px) {
            font-size: 24px;
            font-weight: 400;
        }
    }

    .container {
        padding-top:6rem;
        padding-bottom: 6rem;

        @media (min-width: 850px) {
            max-width: 1200px;
        }

        @media (min-width: 850px) and (max-width: 1149.99px) {
            padding-right: 2.5rem;
            padding-left: 2.5rem;
        }
    }

    .steps {
        gap: 30px;

        @media (min-width: 576px) and (max-width: 849.99px) {
            max-width: 470px;
            margin-left: auto;
            margin-right: auto;
        }
        
        @media (min-width: 850px) {
            margin-left: auto;
            margin-right: auto;
            display: grid;
            gap: 30px;
        }

        @media (min-width: 850px) and (max-width: 1149.99px) {
            grid-template-columns: repeat(2, 1fr);
            row-gap: 0;
        }

        @media (min-width: 1150px) {
            grid-template-columns: repeat(4, 1fr);
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .step {
        border-top: 2px solid var(--color-step-1);
        border-right: 2px solid var(--color-step-1);
        border-top-right-radius: 45px;
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: 240px;
        margin-bottom: 1.5rem;

        @media (max-width: 575.99px) {
            width: 288px;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        @media (min-width: 576px) and (max-width: 1149.99px) {
            flex-direction: row;
            border-bottom: 2px solid var(--color-step-1);
            margin-bottom: 24px;
        }

        @media (min-width: 1150px) {
            margin-bottom: 0;
        }

        &::before {
            content: "";
            width: 2px;
            height: calc(100% - 112px);
            background-color: var(--color-step-1);
            display: block;
            position: absolute;
            top: 0;
            left: 0;

            @media (min-width: 576px) and (max-width: 1149.99px) {
                display: none;
            }
        }

        .step-content {
            padding: 20px 10px 10px 44px;

            @media (min-width: 576px) and (max-width: 849.99px) {
                padding: 42px 25px 4px 54px;
            }

            @media (min-width: 850px) and (max-width: 1149.99px) {
                padding: 42px 25px 10px 54px;
            }

            @media (min-width: 1150px) {
                padding-left: 40px;
                padding-top: 17px;
                padding-right: 20px;
                padding-bottom: 2px;
            }

            a {
                word-break: break-all;
            }
   
        }

        .step-title {
            position: relative;
            font-size: 16px;
            line-height: 20px;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 12px;

            @media (max-width: 575.99px) {
                padding-right: 22px;
            }

            @media (min-width: 850px) and (max-width: 1149.99px) {
                font-size: 15px;
            }

            @media (min-width: 576px) and (max-width: 1149.99px) {
                margin-top: 0;
            }
        }

        p {
            font-size: 15px;
            line-height: 17px;
            margin-top: 12px;
            padding-right: 13px;

            @media (min-width: 576px) and (max-width: 849.99px) {
                font-size: 14px;
                padding-right: 0;
            }

            @media (min-width: 850px) and (max-width: 1149.99px) {
                font-size: 13px;
                padding-right: 0;
            }

            @media (min-width: 1150px) {
                padding-right: 14px;
                font-size: 14px;
            }
        }

        .step-number {
            position: absolute;
            top: 0;
            left: -20px;
        }

        .step-image-container {
            background-color: var(--color-white);
            height: 198px;
            width: 190px;
            flex-shrink: 0;
            margin-top: auto;

            @media (min-width: 576px) and (max-width: 1149.99px) {
                order: -1;
                margin-top: -2px;
            }

            @media (min-width: 576px) and (max-width: 849.99px) {
                width: 160px;
                height: 170px;
            }

            @media (min-width: 850px) and (max-width: 1149.99px) {
                width: 154px;
                height: 162px;
            }

            @media (min-width: 1150px) {
                width: 178px;
                height: 186px;
            }

        }

        .step-image {
            background: var(--color-step-1);
            border-radius: 0 50% 50%;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (min-width: 576px) and (max-width: 1149.99px) {
                border-radius: 50% 0 50% 50%;
            }
        }

        img {
            width: 60%;
        }

        &.even {
            padding-top: 0;
            border-top: 0;
            border-bottom: 2px solid var(--color-step-1);
            border-left: 2px solid var(--color-step-1);
            border-bottom-left-radius: 45px;
            border-top-right-radius: 0;

            @media (min-width: 576px) and (max-width: 1149.99px) {
                border-right: 0;
                border-top: 2px solid var(--color-step-1);
                border-bottom-left-radius: 0;
                border-top-left-radius: 45px;
            }

            &::before {
                display: none;
            }

            .step-image-container {
                order: -1;
                margin-left: -2px;
                margin-top: 0;

                @media (min-width: 576px) and (max-width: 1149.99px) {
                    order: 1;
                    margin-top: -2px;
                }
            }

            .step-image {
                border-radius: 50% 50% 50% 0;

                @media (min-width: 576px) and (max-width: 1149.99px) {
                    border-radius: 0 50% 50%;
                }
            }

            .step-content {
                padding-bottom: 5px;

                .step-title {
                    @media (min-width: 576px) and (max-width: 1149.99px) {
                        padding-right: 22px;
                    }
                }

                p {
                    @media (min-width: 576px) and (max-width: 1149.99px) {
                        padding-right: 30px;
                    }
                }
            }
        }
    }

    .btn-success {
        margin-top: 2.5rem;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        display: block;
        width: fit-content;

        @media (min-width: 850px) {
            margin-top: 6rem;
        }
    }
}