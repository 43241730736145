@use "../tools/mixins";

/*
==========================================================================
   #Heading
========================================================================== */
:root {
    // Heading colors
    --color-h1: #0e0e2c;
    --color-h2: #0e0e2c;
    --color-h3: #0e0e2c;
    --color-h4: #0e0e2c;
    --color-h5: #0e0e2c;
    --color-h6: #0e0e2c;
    --color-subtitle: #11062f;
}

%heading {
    border: 0 none;
    padding: 0;
}

%h1,
.h1,
h1 {
    @extend %heading !optional;
    @include mixins.font(PKFGlobalSans, bold);
    @include mixins.font-size(60px, 72px);
    color: var(--color-h1);
    margin-bottom: 16px;

    @include mixins.breakpoint(mobile) {
        @include mixins.font-size(24px, 28px);
    }
}

%h2,
.h2,
h2 {
    @extend %heading !optional;
    @include mixins.font(PKFGlobalSans, bold);
    @include mixins.font-size(40px, 48px);
    color: var(--color-h2);
    margin-top: 23px;
    margin-bottom: 12px;

    @include mixins.breakpoint(mobile) {
        font-size: 17px;
    }
}

%h3,
.h3,
h3 {
    @extend %heading !optional;
    @include mixins.font(PKFGlobalSans, bold);
    @include mixins.font-size(32px, 36px);
    color: var(--color-h3);
    margin-top: 23px;
    margin-bottom: 12px;

    @include mixins.breakpoint(mobile) {
        @include mixins.font-size(18px, 24px);
    }
}

%h4,
.h4,
h4 {
    @extend %heading !optional;
    @include mixins.font(PKFGlobalSans, bold);
    @include mixins.font-size(24px, 28px);
    color: var(--color-h4);
    margin-top: 23px;
    margin-bottom: 10px;

    @include mixins.breakpoint(mobile) {
        @include mixins.font-size(18px, 24px);
    }
}

%h5,
.h5,
h5 {
    @extend %heading !optional;
    @include mixins.font(PKFGlobalSans, bold);
    @include mixins.font-size(20px, 28px);
    color: var(--color-h5);
    margin-top: 25px;
    margin-bottom: 10px;

    @include mixins.breakpoint(mobile) {
        @include mixins.font-size(16px, 24px);
    }
}

%h6,
.h6,
h6 {
    @extend %heading !optional;
    @include mixins.font(PKFGlobalSans, bold);
    @include mixins.font-size(16px, 28px);
    color: var(--color-h6);
    margin-top: 25px;
    margin-bottom: 10px;

    @include mixins.breakpoint(mobile) {
        @include mixins.font-size(16px, 24px);
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    a,
    a:focus,
    a:visited {
        color: inherit;
        text-decoration: none;
        font-family: inherit;
        font-size: inherit;
    }

    a:hover {
        color: inherit;
        text-decoration: underline;
    }
}

/*
==========================================================================
   #Paragraph
========================================================================== */

p {
    margin-top: 10px;
    margin-bottom: 16px;
}

.subtitle {
    @include mixins.font-size(12px, 16px);
    @include mixins.font(PKFGlobalSans, bold);
    text-transform: uppercase;
    letter-spacing: 0.4px;
    color: var(--color-subtitle);
}

.text-small {
    @include mixins.font-size(12px, 16px);
}
