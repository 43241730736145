.progressbar {
    --progress-color: #003594;
    --progress-background-color: #f0f0f0;
    width: 100%;
    display: none;
    margin-bottom: 10px;
    margin-top: 10px;

    progress {
        width: 100%;
    }
}

