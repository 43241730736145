@use "../tools/mixins";

/*
==========================================================================
   #Variables
   Only add Global variables(Variables that are being shared between different modules) here. Local variables should be added into the related module
========================================================================== */

:root {
    /*
    * page head
    */
    --head-height: auto;

    /*
    * Wrapper
    */
    --wrapper-width: 1200px;
    --wrapper-padding-h: 10px;

    /*
    * Drop-down arrow transition time
    */
    --arrow-transition-time: 0.2s;

    /*
    * Global transition time
    */
    --transition-time: 0.2s;
    --transition-time-slow: 0.5s;

    /*
    * Global focus width
    */
    --focus-border-width: 3px;
    --outline-offset: 2px;

    /*
    * hr
    */
    --hr-border-width: 1.5px;

    /*
    * side menu width
    */
    --sidebar-width: 86px;
}

@include mixins.breakpoint(tablet) {
    :root {
        --wrapper-padding-h: 30px;
        --head-height: 96px;
    }
}

@include mixins.breakpoint(mobile) {
    :root {
        --wrapper-padding-h: 20px;
        --head-height: 96px;
        --sidebar-width: 60px;
    }
}
