.header.organisation-page-header{
    --hero-bg: #003594;
    --header-bg: #003594;
    --header-text: #ffffff;
    --header-btn: #ffffff;
    --header-text-hover: #cccccc;
    --language-dropdown-text: #003594;
    --number-weight: 400;
    --icon-close: #ffffff;

    font-family: PKFGlobalSans;

    a {
        font-family: PKFGlobalSans;
    }

    .base-template__optuspage & {
        --hero-bg: #ffffff;
        --header-btn: #003594;
        --number-weight: 700;
        --icon-close: #ffffff;

        @media (min-width: 850px) {
            --header-text: #003594;
            --header-text-hover: #023292;
        }

        &.active {
            --hero-bg: #003594;

            .logo {
                display: none;
            }
        }

        .logo-white {
            display: none;
        }

        .logo-dark {
            display: block;
        }

        .optus-logo {
            @media (min-width: 576px) {
                display: block;
                height: 27px;
                width: auto;
                margin-left: 2.5rem !important;
            }
        }
    }

    background-color: var(--hero-bg);
    padding: 0.5rem 1rem;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    position: relative;

    &.active {
        position: fixed;
    }

    @media (min-width: 576px) {
        padding: 1rem 2.5rem;
    }

    @media (min-width: 850px) {
        position: sticky;
        box-shadow: 0 .4rem 1rem rgba(0, 0, 0, .15);
    }

    .header__container {
        align-items: center;
    }

    .logo {
        height: 52px;
        width: auto;
    }

    .logo-dark {
        display: none;
    }

    .optus-logo {
        display: none;
    }

    .btn-menu {
        padding: 0;
        display: inline-block;
        background: transparent;
        color: var(--header-btn);
        border: 0;
        height: auto;
        line-height: 0;
        margin-left: auto;

        @media (min-width: 850px) {
            display: none;
        }

        svg {
            width: 60px;
            height: 60px;
            color: inherit;
        }

        .close-icon {
            display: none;
        }

        .open-icon {
            display: block;
        }

        &.active {
            .close-icon {
                display: block;
                color: var(--icon-close);
            }

            .open-icon {
                display: none;
            }
        }
    }

    .header__btns {
        margin-left: auto;
        gap: 2.5rem;

        @media (max-width: 849.99px) {
            display: none;
        }
    }

    .btn-outline-white {
        padding: .8rem 2rem;

        @media (min-width: 1150px) {
            min-width: 138px;
        }
    }

    .header__btns-region {
        text-align: center;
    }

    .btn-selected-number {
        color: var(--header-text);
        margin-top: 0;
        margin-bottom: 0;

        a {
            color: inherit  ;
            text-decoration: none;
            font-weight: var(--number-weight);
        }

        a:hover {
            color: var(--header-text-hover);
        }
    }

    .region-list {
        display: flex;
        gap: 0.5rem;
        justify-content: center;
        line-height: 24px;
    }

    .region-item {
        color: var(--header-text-hover);
        text-transform: uppercase;
        font-size: 80%;
        font-weight: 400;

        &:hover,
        &.active {
            color: var(--header-text);
            text-decoration: underline;
        }
    }

    .language-dropdown {
        position: relative;
        display: inline-block;

        &__label {
            color: var(--header-text);
            background-color: transparent;
            border: 0;
            padding: .8rem 0;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.2;
            text-transform: initial;
            font-family: PKFGlobalSans;

            &::after {
                content: url("../icons/arrow-down.svg");
                display: inline-block;
                margin-left: 0.5rem;
                width: 16px;
                height: 16px;
            }

            &:focus {
                outline: 0;
            }
        }

        &__list {
            display: none;
            position: absolute;
            top: calc(100% + 20px);
            background-color: var(--header-text);
            color: var(--language-dropdown-text);
            min-width: 200px;
            list-style: none;
            padding: 1rem;
            border-radius: 0 0 4px 4px;
            margin-top: 0;

            &.show {
                display: block;
            }

            a {
                text-decoration: none;
                display: block;
                padding: 0.5rem 0;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .mobile-menu {
        display: none;
        padding-left: 1rem;
        padding-right: 1rem;
        &.active {
            display: flex;
            flex-direction: column;
            position: fixed;
            top: auto;
            left: 0;
            right: 0;
            height: 100%;
            background-color: var(--header-bg);
            z-index: 2000;
        }

        a {
            width: 100%;
            color: var(--header-text);
            font-size: 29px;
            display: block;
            padding: 17px 0;
            border-bottom: 1px solid var(--header-text);
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
