@use "../tools/mixins";

/*
==========================================================================
   #Page
========================================================================== */

html {
    @include mixins.font(PKFGlobalSans, reg);
    @include mixins.font-size(var(--base-font-size), var(--base-line-height));
    color: var(--base-text-color);
    text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

body {
    margin: 0;
    padding: 0;
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
