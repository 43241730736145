@use "../tools/functions";
@use "../tools/mixins";

/*
==========================================================================
   #Form elements
========================================================================== */
:root {
    --form-element-border-width: 1px;
    --form-element-padding-v: 5px;
    --form-element-padding-h: 10px;
    --select-padding-right: 58px;
    --textarea-padding-v: 10px;
    --textarea-padding-h: 10px;
    --checkbox-height: 20px;
    --checkbox-width: 20px;
    --checkbox-padding-left: 10px;
    --checkbox-tick-position-left: 2px;
    --checkbox-tick-size: 5px;
    --checkbox-border-width: 1.5px;
    --radio-size-inner: 10px;
    --radio-size-outer: 20px;
    --radio-padding-left: 7px;
    --radio-border-width: 2px;

    // From element colors
    --color-form-element-border: #9a9a9a;
    --color-form-element-disabled-bg: #f4f5f6;
    --color-form-element-disabled-border: #dee2e7;
    --color-form-element-text: #2a2525;
    --color-form-element-bg: #ffffff;
    --color-form-element-hover-bg: #ffffff;
    --color-form-element-focus-shadow: #66afe9;
    --color-select-bg: #ffffff;
    --color-error-border: #eb615d;
    --color-error-text: #eb615d;
    --color-form-error-text: #b94a48;
    --color-form-error-bg: #fff4f5;
    --color-form-border: #b94a48;

    // Label colors
    --color-label-text: #11062f;
    --color-label-disabled: #707070;

    // Radio colors
    --color-radio-bg: #ffffff;
    --color-radio-checked-bg: #505a69;
    --color-radio-border: #505a69;
    --color-checkbox-checked-disabled: #707070;

    // Checkbox colors
    --color-checkbox-bg: #ffffff;
    --color-checkbox-bg-active: #043394;
    --color-checkbox-bg-hover: #d6edff;
    --color-checkbox-border: #505a69;
    --color-checkbox-border-active: #043394;
    --color-checkbox-border-hover: #043394;
    --color-checkbox-border-disabled: #9a9a9a;
    --color-checkbox-border-focused: #505a69;
    --color-checkbox-tick: #ffffff;
    --color-checkbox-tick-disabled: #707070;
}

/**
 * Placeholder
 */

/* stylelint-disable */

::-webkit-input-placeholder {
    color: inherit;
    opacity: .6;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: inherit;
    opacity: .6;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: inherit;
    opacity: .6;
}

:-ms-input-placeholder {
    color: inherit;
    opacity: .6;
}
/* stylelint-enable */

legend,
.legend {
    @include mixins.font-size(16px, 24px);
    @include mixins.font(PKFGlobalSans, reg);
    margin-right: 14px;
}

/**
 * Label
 */

label {
    color: var(--color-label-text);
    margin-bottom: 3px;
    display: inline-block;

    @include mixins.font-size(16px, 24px);
    @include mixins.font(PKFGlobalSans, reg);
}

textarea {
    height: 128px;
    resize: none;
    padding: var(--textarea-padding-v) var(--textarea-padding-h);
}

%input,
input[type="email"],
input[type="number"],
input[type="password"],
input[type="tel"],
input[type="url"],
input[type="text"],
input[type="search"],
input[type="date"],
textarea,
select {
    display: block;
    color: var(--color-form-element-text);
    border: var(--form-element-border-width) solid
        var(--color-form-element-border);
    border-radius: 0 4px;
    padding: 10px var(--form-element-padding-h) 10px;
    max-width: 100%;
    width: 100%;
    min-height: 46px;
    background-color: var(--color-form-element-bg);
    transition: background-color var(--transition-time),
        color var(--transition-time);

    @include mixins.font-size(16px, 24px);
    @include mixins.font(PKFGlobalSans, reg);
    appearance: none; /* Remove ios text input inner shadow */

    @include mixins.breakpoint(mobile) {
        width: 100%;
    }

    &:focus {
        outline: 0;
    }

    &:focus,
    .no-touch &:hover {
        background-color: var(--color-form-element-hover-bg);
    }

    &:disabled {
        background-color: var(--color-form-element-disabled-bg);
        border-color: var(--color-form-element-disabled-border);
        pointer-events: none;
        cursor: not-allowed;
    }
}

/**
 * Select
 */

select,
select[multiple] {
    appearance: none;
    cursor: pointer;
    padding-right: var(--select-padding-right);

    background-image: url("../icons/dropdown-arrow-2.svg"),
        url("../icons/dropdown-arrow-box.svg");
    background-position: right 15px center, right -1px center;
    background-repeat: no-repeat;

    // Hide default select arrow in IE

    &::-ms-expand {
        display: none;
    }

    &:focus,
    .no-touch &:hover {
        background-color: transparent;
    }
}

select[multiple] {
    height: 48px;
    overflow: hidden;

}

/**
 * Checkbox
 */

input[type="checkbox"] {
    position: absolute;
    left: -9999px;
    opacity: 0;
    + label {
        position: relative;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        padding-left: calc(
            var(--checkbox-width) + var(--checkbox-padding-left)
        );
        min-height: var(--checkbox-height);
        margin-bottom: 10px;

        @include mixins.font-size(14px, 21px);
        @include mixins.font(PKFGlobalSans, reg);

        &::after {
            content: "";
            position: absolute;
            display: block;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: var(--checkbox-width);
            height: var(--checkbox-height);
            background-color: var(--color-checkbox-bg);
            border: var(--checkbox-border-width) solid
                var(--color-checkbox-border);
            border-radius: 0 3px;
        }

        @include mixins.breakpoint(mobile) {
            margin-bottom: 22px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .ripple {
            left: -4px;
            position: absolute;
            top: 50%;
            background: transparent;
            display: block;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            transform: translateY(-50%);
        }

        &:hover {
            .ripple {
                background: var(--color-checkbox-bg-hover);
            }

            &::after {
                border-color: var(--color-checkbox-border-hover);
            }
        }
    }

    &:checked {
        + label {
            &::before {
                position: absolute;
                left: var(--checkbox-tick-position-left);
                top: 50%;
                transform: translateY(-50%);
                z-index: functions.z-index(check-box);
                color: var(--color-checkbox-tick);
                font-size: var(--checkbox-tick-size);
                transition: all var(--transition-time);

                @extend %icon, %icon-tick !optional;
            }

            &::after {
                background-color: var(--color-checkbox-bg-active);
                border-color: var(--color-checkbox-border-active);
            }
        }
    }

    &:focus + label {
        &::after {
            outline: 0;
            box-shadow: 0 0 6px 6px rgba(17, 6, 47, 0.2);
            border-color: var(--color-checkbox-border-focused);
        }

        .ripple {
            background-color: transparent;
        }
    }

    &:disabled + label {
        color: var(--color-label-disabled);
        cursor: default;

        &::after {
            border-color: var(--color-checkbox-border-disabled);
        }
    }
}

/**
* Radio
*/

input[type="radio"] {
    position: absolute;
    left: -9999px;
    opacity: 0;
    + label {
        position: relative;
        display: inline-flex;
        align-items: center;
        padding-left: calc(
            var(--radio-size-outer) + var(--radio-padding-left)
        );
        cursor: pointer;
        margin-bottom: 10px;
        margin-right: 32px;
        box-sizing: border-box;
        min-height: var(--radio-size-outer);

        @include mixins.font-size(14px, 21px);
        @include mixins.font(PKFGlobalSans, reg);

        @include mixins.breakpoint(mobile) {
            margin-bottom: 22px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: var(--radio-size-outer);
            height: var(--radio-size-outer);
            box-sizing: border-box;
            background-color: var(--color-radio-bg);
            border: var(--radio-border-width) solid var(--color-radio-border);
            border-radius: 50%;
        }
    }

    &:checked + label {
        &::before {
            content: "";
            position: absolute;
            left: calc(
                (var(--radio-size-outer) - var(--radio-size-inner)) / 2
            );
            top: 50%;
            transform: translateY(-50%);
            height: var(--radio-size-inner);
            width: var(--radio-size-inner);
            z-index: functions.z-index(radio);
            background-color: var(--color-radio-checked-bg);
            transition: all var(--transition-time);
            border-radius: 50%;
        }
    }

    &:focus + label {
        &::after {
            outline: var(--focus-border-width) solid var(--color-focus);
        }
    }

    &:disabled {
        + label {
            color: var(--color-label-disabled);
            cursor: default;

            &::after {
                border-color: var(--color-form-element-disabled-border);
                background-color: var(--color-form-element-disabled-bg);
            }
        }

        &:checked + label::before {
            background-color: var(--color-checkbox-checked-disabled);
        }
    }
}

/**
 * Buttons
 */

%button,
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    border: 0;
}

fieldset {
    border: 0;
    padding: 0;
    margin-left: 0;
}
