@use "../tools/mixins";

/*
==========================================================================
   #Buttons
========================================================================== */
:root {
    --btn-padding-v: 8px;
    --btn-padding-h: 16px;
    --btn-focus-border-width: 2px;

    // Button clolors
    --btn-focus-color: #032161;
    --color-btn-primary-text: #ffffff;
    --color-btn-primary-bg: #043394;
    --color-btn-primary-text-hover: #ffffff;
    --color-btn-primary-bg-hover: #032161;
    --color-btn-primary-bg-disabled: #567bc7;
    --color-btn-secondary-text: #11062f;
    --color-btn-secondary-border: transparent;
    --color-btn-secondary-bg: transparent;
    --color-btn-secondary-text-hover: #11062f;
    --color-btn-secondary-bg-hover: #9a9a9a;
    --color-btn-secondary-bg-disabled: transparent;
    --color-btn-secondary-text-disabled: #9a9a9a;
    --color-btn-tertiary-text: #ffffff;
    --color-btn-tertiary-bg: #11062f;
    --color-btn-tertiary-text-hover: #ffffff;
    --color-btn-tertiary-bg-hover: #11062f;
    --color-btn-tertiary-bg-disabled: #9a9a9a;
}

%button,
button,
input[type="button"],
input[type="reset"],
input[type="submit"],
%btn,
.btn {
    display: inline-block;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    text-align: center;
    text-decoration: none;
    cursor: pointer;

    @include mixins.font-size(16px, 24px);
    @include mixins.font(PKFGlobalSans, semibold);
    text-transform: uppercase;
    padding: var(--btn-padding-v) var(--btn-padding-h);

    @include mixins.breakpoint(mobile) {
        margin-bottom: 10px;
    }

    &:focus,
    &:hover {
        text-decoration: none;
    }

    &:focus {
        outline: var(--btn-focus-border-width) solid var(--btn-focus-color);
        outline-offset: 2px;
    }

    &:active {
        box-shadow: none;
    }

    a {
        display: inline-block;
        text-decoration: none;
        color: inherit;

        &:hover,
        &:focus {
            color: inherit;
        }
    }
}

.btn-primary {
    @extend %btn !optional;
    color: var(--color-btn-primary-text);
    background-color: var(--color-btn-primary-bg);
    background-position: 0 0;
    background-size: 100% 200%;
    background-image: linear-gradient(
        180deg, var(--color-btn-primary-bg) 50%, var(--color-btn-primary-bg-hover) 0
    );
    transition: background-position 0.25s;

    @include mixins.hover-prop {
        color: var(--color-btn-primary-text);
        background-position: 0 100%;
    }

    &.disabled,
    &:disabled {
        background-color: var(--color-btn-primary-bg-disabled);
        background-image: linear-gradient(
            180deg, var(--color-btn-primary-bg-disabled) 50%
        );
        pointer-events: none;
    }
}

.btn-secondary {
    @extend %btn !optional;
    color: var(--color-btn-secondary-text);
    background-color: var(--color-btn-secondary-bg);
    background-position: 0 0;
    background-size: 100% 200%;
    background-image: linear-gradient(
        180deg, var(--color-btn-secondary-bg) 50%, var(--color-btn-secondary-bg-hover) 0
    );
    transition: background-position 0.25s;

    @include mixins.hover-prop {
        background-position: 0 100%;
        color: var(--color-btn-secondary-text-hover);
    }

    &.disabled,
    &:disabled {
        background-color: var(--color-btn-secondary-bg-disabled);
        background-image: linear-gradient(
            180deg, var(--color-btn-secondary-bg-disabled) 50%
        );
        color: var(--color-btn-secondary-text-disabled);
        pointer-events: none;
    }
}

.btn-tertiary {
    @extend %btn !optional;
    color: var(--color-btn-tertiary-text);
    background-color: var(--color-btn-tertiary-bg);
    border: 0;
    border-radius: 50px;
    padding: 18px 16px;
    min-width: 227px;
    text-decoration: none;

    @include mixins.font-size(16px, 24px);
    @include mixins.font(PKFGlobalSans, semibold);
    @include mixins.hover(
        var(--color-btn-tertiary-text-hover),
        var(--color-btn-tertiary-bg-hover)
    );

    @include mixins.hover-prop {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14), 0 4px 5px rgba(0, 0, 0, 0.12),
            0 1px 10px rgba(0, 0, 0, 0.2);
        text-decoration: none;
    }

    &::before {
        content: "";
        background: url("../icons/add.svg");
        background-size: contain;
        background-repeat: no-repeat;
        width: 14px;
        height: 14px;
        display: inline-block;
        color: inherit;
        margin-right: 10px;
        text-decoration: none;
    }

    &.disabled,
    &:disabled {
        background-color: var(--color-btn-tertiary-bg-disabled);
        pointer-events: none;
    }
}
