.base-template__helppage  {
    --link-color: #003594;
    --link-hover-color: #001a48;
    --position-color: #000000;

    &.base-template__optuspage {
        .btn-success {
            --color-success: #62bb46;
            --color-success-text: #ffffff;
            --color-success-hover: #53a03b;
            --color-success-border-hover: #4e9737;
            --color-success-text-hover: #ffffff;
            --filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7494%) hue-rotate(337deg) brightness(105%) contrast(100%);
            --filter-hover: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7494%) hue-rotate(337deg) brightness(105%) contrast(100%);
        }
    }

    main {
        a:not(.btn, .page-modal a) {
            color: var(--link-color);

            text-decoration: none;

            &:hover {
                text-decoration: underline;
                color: var(--link-hover-color);
            }
        }

        ol:not(.page-modal ol) {
            color: var(--text-color);
        }

        h1:not(.page-modal h1) {
            margin-top: 0;
        }

        h2:not(.page-modal h2) {
            font-size: 1.75rem;
            line-height: 1.36;
            margin-bottom: 1em;

            @media (max-width: 849.98px) {
                font-size: 1.6rem;

            }

            @media (max-width: 575.98px) {
                    font-size: 1.4rem;
            }
        }
    }

    .contact-section_row {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 14px;

        @media (min-width: 576px) {
            gap: 30px;
            grid-template-columns: repeat(2, 1fr);
        }

        .contact-section_col {
            margin-bottom: 2rem;

            h3 {
                font-size: 1.25rem;

                margin-bottom: 2rem;
                font-weight: 400;
            }

            .person {
                .name {
                    color: var(--position-color);
                }
                p {
                    margin: 0;
                }
            }
        }

    }

    .content {
        padding-left: 1rem;
        padding-right: 1rem;

        @media (min-width: 576px) {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
        }

        @media (min-width: 1150px) {
            padding-right: 6rem;
            padding-left: 6rem;
        }

        &.plain {
            padding-top: 3rem;
            padding-bottom: 6rem;
            margin-left: 310px;
            max-width: 900px;
            width: 100%;
            margin-top: 0;

            @media (max-width: 1399.98px) {
                    margin: 0 auto;
            }

            @media (min-width: 850px) {
                padding-top: 6rem;
                padding-bottom: 6rem;
            }

            @media (max-width: 1399.98px) {
                margin: 0 auto;
            }

            p {
                font-size: 18px;
                margin-bottom: 2rem;

                    @media (max-width: 575.98px) {
                    font-size: 1rem;
                }
            }
        }
    }
}
