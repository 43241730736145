/*
==========================================================================
   #Wrapper
========================================================================== */

.wrapper {
    display: block;
    width: 100%;
    max-width: var(--wrapper-width);
    padding: 0 var(--wrapper-padding-h);
    margin: auto;
    box-sizing: border-box;
}
