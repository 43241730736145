body footer.footer-org {
    --footer-bg: #000000;
    --footer-text: #ffffff;
    --footer-link: #d8d8d8;

    background-color: var(--footer-bg);
    color: var(--footer-text);
    padding: 3rem 1rem 0.5rem;
    margin-top: auto;

    @media (min-width: 576px) {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }

    a {
        color: var(--footer-text);
    }

    .logo {
        width: 83px;
        height: auto;
    }

    .footer-content {
        display: flex;
        flex-wrap: wrap;

        &__description {
            @media (min-width: 1150px) {
                flex: 0 0 50%;
                max-width: 50%;
                padding-right: 15px;
            }
        }

        &__links {
            padding-top: 1rem;

            @media (min-width: 1150px) {
                margin-left: 8.3333333333%;
                max-width: 41.6666666667%;
                flex: 0 0 41.6666666667%;
                padding-left: 15px;
                padding-right: 15px;
                padding-top: 0;
            }
            a {
                display: block;
                margin-bottom: .5rem;
                text-decoration: none;
                color: var(--footer-link);

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .copyright {
        margin-top: 2.5rem;
        margin-bottom: .5rem;
    }
}
