.loading-message {
    --spinner-color: #003594;
    --spinner-background: #ffffff;
    text-align: center;
    display: flex;
    margin-right: 10px;
    margin-top: 0;
    margin-bottom: 0;
    .spinner {
        border: 4px solid var(--spinner-background);
        border-radius: 50%;
        border-top: 4px solid var(--spinner-color);
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;
        margin: 0;
    }
    
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
}
