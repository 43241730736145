.page-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-y: auto;
    display: none;

    .page-modal__content {
        position: relative;
        background-color: #ffffff;
        min-height: calc(100vh - 100px);
        display: flex;
        flex-direction: column;
    }

    .page-modal__body {
        flex: 1;
        padding: 20px;
    }
}