@use "../tools/mixins";

.mr-top-auto {
    margin-top: auto;
}

.mr-top-sm {
    margin-top: 2rem;
}

.mr-left-auto {
    margin-left: auto;
}

.mr-left-sm {
    margin-left: 10px;
}

.mr-left-md {
    margin-left: 20px;
}

.mr-bottom-xs {
    margin-bottom: 10px;
}

.mr-bottom-sm {
    margin-bottom: 20px;
}

.mr-bottom-md {
    margin-bottom: 32px;
}

.pt-sm {
    padding-top: 20px;
}

.pb-sm {
    padding-bottom: 20px;
}

.no-mr-top {
    margin-top: 0;
}

.no-mr-bottom {
    margin-bottom: 0;
}

.align-self-center {
    align-self: center;
}

.align-center {
    align-items: center;
}

.text-align-right {
    text-align: right;
}

.space-between {
    justify-content: space-between;
}

.ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.center-element {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.uppercase {
    text-transform: uppercase;
}

.font-light {
    @include mixins.font(PKFGlobalSans, light);
}

.font-reg {
    @include mixins.font(PKFGlobalSans, reg);
}

.font-semibold {
    @include mixins.font(PKFGlobalSans, semibold);
}

.font-bold {
    @include mixins.font(PKFGlobalSans, bold);
}

.font-size-sm {
    font-size: 1.2em;
}

.flex {
    display: flex;
}

.flex-dk {
    @include mixins.breakpoint(desktop) {
        display: flex;
    }
}

.flex-md {
    @include mixins.breakpoint(tablet) {
        display: flex;
    }
}

.hide {
    display: none;
}

.border-bottom {
    border-bottom: var(--hr-border-width) solid var(--color-hr);
}

.break-word {
    word-break: break-word;
}

.bg-light-grey {
    background-color: var(--color-1);
    border-radius: 0 10px;
}

.card {
    border: 1px solid var(--color-3);
    padding: 20px;
}

.gap-xs {
    gap: 5px;
}

.gap-sm {
    gap: 10px;
}

.gap-md {
    gap: 20px;
}

.gap-2 {
    gap: 1rem;
}

.cursor-pointer {
    cursor: pointer;
}

.width-300 {
    max-width: 100%;
    width: 300px;
}
