@use "../tools/mixins";

:root {
    --input-padding-hr: 14px;
    --color-label-text-active: #11062f;
    --color-label-dropdown-active: #043394;
    --color-input-border-active: #11062f;
    --color-select-bg: #ffffff;
    --color-select-border: #043394;
    --color-select-border-hover: #9a9a9a;
}

.small-form-field {
    label {
        display: inline-block;
        margin-bottom: 0;

        @include mixins.font-size(16px, 24px);
        @include mixins.font(PKFGlobalSans, reg);
        margin-right: 15px;
    }

    %input,
    input[type="email"],
    input[type="number"],
    input[type="password"],
    input[type="tel"],
    input[type="url"],
    input[type="text"],
    input[type="search"],
    input[type="date"],
    textarea,
    select {
        @include mixins.font-size(14px, 20px);
        @include mixins.font(PKFGlobalSans, reg);
        border: 0;
        border-bottom: 1.5px solid #9a9a9a;
        border-radius: 0;
    }

    input[type="email"]:focus,
    input[type="number"]:focus,
    input[type="password"]:focus,
    input[type="tel"]:focus,
    input[type="url"]:focus,
    input[type="text"]:focus,
    input[type="search"]:focus,
    textarea:focus,
    select:focus {
        + .field--outline {
            position: absolute;
            border-bottom: 2px solid var(--color-input-border-active);
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            pointer-events: none;
        }
    }

    input[type="date"] {
        border: 0;
    }

    select {
        background-image: url("../icons/dropdown-arrow.svg");
        background-position: right 12px center;
    }

    .field-wrapper {
        display: inline-block;
    }

    .fieldInputWrapper {
        display: flex;
        align-items: flex-end;
    }
}

.medium-form-field {
    input[type="email"]:focus,
    input[type="number"]:focus,
    input[type="password"]:focus,
    input[type="tel"]:focus,
    input[type="url"]:focus,
    input[type="text"]:focus,
    input[type="search"]:focus,
    textarea:focus,
    select:focus {
        + .field--outline {
            position: absolute;
            border: 2px solid var(--color-input-border-active);
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border-radius: 0 10px;
            pointer-events: none;
        }
    }

    .fieldWrapper {
        margin-bottom: 20px;
    }

    .fieldInputWrapper {
        position: relative;

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            animation: autofill 0s forwards;
            box-shadow: 0 0 0 1000px var(--color-form-element-bg) inset;
            transition: background-color 5000s ease-in-out 0s;
        }

        @keyframes autofill {
            100% {
                background: var(--color-form-element-bg);
                color: inherit;
            }
        }

        input[type="email"],
        input[type="number"],
        input[type="password"],
        input[type="tel"],
        input[type="url"],
        input[type="text"],
        input[type="search"],
        input[type="date"],
        textarea,
        select {
            &:focus {
                box-shadow: 0 0 6px 6px rgba(17, 6, 47, 0.2);
            }

            &.invalid {
                border: 2px solid var(--color-error-text);
            }
        }
    }

    .has-icon {
        input[type="email"],
        input[type="number"],
        input[type="password"],
        input[type="tel"],
        input[type="url"],
        input[type="text"],
        input[type="search"],
        textarea,
        select {
            padding-left: 51px;
        }
    }
}

.large-form-field {
    label {
        display: block;
        margin-bottom: 10px;
    }

    input[type="email"]:focus,
    input[type="number"]:focus,
    input[type="password"]:focus,
    input[type="tel"]:focus,
    input[type="url"]:focus,
    input[type="text"]:focus,
    input[type="search"]:focus,
    textarea:focus,
    select:focus {
        + .field--outline {
            position: absolute;
            border: 2px solid var(--color-input-border-active);
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border-radius: 0 10px;
            pointer-events: none;
        }
    }

    .has-icon {
        input[type="email"],
        input[type="number"],
        input[type="password"],
        input[type="tel"],
        input[type="url"],
        input[type="text"],
        input[type="search"],
        textarea,
        select {
            padding-left: 51px;
        }
    }

    &.horizontal {
        label,
        .field-wrapper {
            display: inline-block;
        }

        label {
            margin-right: 20px;
        }
    }
}

.select-floating-label {
    position: relative;

    select {
        min-width: 176px;
        width: auto;
        background-color: var(--color-select-bg);
        background-image: url("../icons/dropdown-arrow.svg");
        background-position: right 12px center;
        border: 0;
        border-radius: 0;
        border-bottom: 1.5px solid var(--color-select-border);
        padding-bottom: 9px;
        margin-bottom: 5px;

        @include mixins.font(PKFGlobalSans, reg);
        transition: none;
        padding-right: 28px;

        &:hover {
            box-shadow: (0 2px 4px rgba(0, 0, 0, 0.2));
            border-bottom-color: var(--color-select-border-hover);
        }
    }

    label {
        position: absolute;
        left: var(--input-padding-hr);
        top: 15px;
        transition: all var(--transition-time);

        @include mixins.font-size(12px, 16px);
        @include mixins.font(PKFGlobalSans, reg);
        z-index: 1;
    }

    &.active label {
        @include mixins.font-size(14px, 21px);
        @include mixins.font(PKFGlobalSans, reg);
        top: -25px;
        color: var(--color-label-text-active);
        left: 0;
    }

    input:-webkit-autofill ~ label {
        @include mixins.font-size(14px, 21px);
        top: -25px;
        color: var(--color-label-text-active);
    }

    .fieldInputWrapper {
        &.active label {
            @include mixins.font-size(8px, 16px);
            text-transform: uppercase;
            top: 3px;
            color: var(--color-label-dropdown-active);
            left: 12px;
        }
    }
}

.field-wrapper {
    position: relative;

    &.icon-search::before {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        content: "";
        background: url("../icons/search.svg");
        width: 18px;
        height: 18px;
        background-size: contain;
    }
}

.fieldMessageWrapper {
    @include mixins.font-size(12px, 16px);
    @include mixins.font(PKFGlobalSans, reg);

    &.error {
        @include mixins.font(PKFGlobalSans, bold);
        font-style: italic;
        padding-left: 0;
        color: var(--color-error-text);

        ul {
            margin: 0;
            list-style: none;
            padding-left: 0;
        }

        li {
            margin-bottom: 0;
            padding-top: 6px;
            color: var(--color-error-text);
        }
    }

    &.margin-bottom {
        margin-bottom: 5px;
    }
}

.fieldWrapper {
    position: relative;
    margin-bottom: 15px;

    .error {
        input[type="email"],
        input[type="number"],
        input[type="password"],
        input[type="tel"],
        input[type="url"],
        input[type="text"],
        input[type="search"],
        textarea,
        select {
            border: 2px solid var(--color-error-border);
        }
    }

    &.half-width {
        width: calc(50% - 10px);

        @include mixins.breakpoint(mobile) {
            width: 100%;
        }
    }

    &.quarter-width {
        width: calc(25% - 10px);

        @include mixins.breakpoint(mobile) {
            width: 100%;
        }
    }
}

input::-webkit-datetime-edit {
    flex: 1 1 auto;
}

input[type="date"] {
    font-size: 14px;
    line-height: 21px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background-image: url("../icons/date.svg");
    margin-left: 13px;
}

.form-half-width {
    @include mixins.breakpoint(desktop) {
        width: 50%;
    }
}

.form-btn-container {
    display: flex;
    justify-content: end;
    margin-top: 20px;
    gap: 10px;

    &.wrap {
       flex-wrap: wrap;
    }
}

.form-heading {
    @extend %h4 !optional;
    margin-top: 0;
    margin-bottom: 20px;
}

.grecaptcha-badge {
    z-index: 10;
}

.checkboxes,
.radio {
    .choice-wrapper {
        label:not(:last-of-type) {
            margin-right: 50px;
        }
    }
}

div.InvisibleReCaptchaField,
div.AjaxInvisibleReCaptchaField {
    display: none;
}
.checkbox {
    .subcategory-group {
        margin-bottom: 20px;

        @include mixins.breakpoint(not-mobile) {
            column-count: 2;
        }
    }
}


.select2 {
    --color-border: #043394;
    --color-bg: #ffffff;
    &.select2-container--default {
        .select2-selection--multiple {
            .select2-selection__choice {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border-radius: 20px;
                border: 2px solid var(--color-border);
                padding: 6px 14px;

                @include mixins.font-size(14px, 21px);
                background-color: var(--color-bg);
                padding-right: 44px;
            }

            .select2-selection__choice__remove {
                background-color: var(--color-border);
                background-image: url("../icons/close-white.svg");
                background-position: 50%;
                background-size: 7.5px;
                background-repeat: no-repeat;
                border-radius: 50%;
                height: 15px;
                margin-left: 11.5px;
                width: 15px;
                font-size: 0;
                line-height: 0;
                border: 0;
                left: auto;
                right: 14px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .select2-search--inline .select2-search__field {
        margin-top: 0;
        height: auto;
    }

    .counter {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
    }
}
