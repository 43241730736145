// Import only the tools and settings needed
@use "../tools/mixins";

.landing-page {
    --color-white: #ffffff;
    --color-title: #003594;

    font-family: PKFGlobalSans;
    line-height: 1.5;
    font-size: 1rem;
    font-weight: 400;

    @media (min-width: 850px) {
        .col {
            &-md-8 {
                flex: 0 0 66.6666666667%;
                max-width: 66.6666666667%;
            }

            &-md-3 {
                flex: 0 0 25%;
                max-width: 25%;
            }
        }
    }

    &.base-template__optuspage {
        .center {
            text-align: left;
        }
    }

    h1:not(.page-modal h1) {
        font-weight: 400;
        font-size: 43px;
        letter-spacing: 0.86px;
        line-height: 1.25em;

        @media (max-width: 849.99px) {
            font-size: 28px;
        }
    }
}
