.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media(min-width: 576px) {
    .container,.container-sm {
        max-width:700px
    }
}

@media(min-width: 850px) {
    .container,.container-md,.container-sm {
        max-width:1000px
    }
}

@media(min-width: 1150px) {
    .container,.container-lg,.container-md,.container-sm {
        max-width:1200px
    }
}

@media(min-width: 1400px) {
    .container,.container-lg,.container-md,.container-sm,.container-xl {
        max-width:1390px
    }
}

@media(min-width: 1700px) {
    .container,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl {
        max-width:1690px
    }
}