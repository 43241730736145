.banner-block {
    --hero-bg: #003594;
    --color-white: #ffffff;

    .base-template__optuspage & {
        --hero-bg: #00a2ad;

        h1 {
            margin-bottom: 1.5rem;
        }

        .btn-success {
            margin-top: 16px;
            margin-bottom: 2.5rem;

            @media (max-width: 1149.99px) {
               display: none;
            }
        }

        .container {
            padding: 6rem 2.5rem 10rem 2.5rem;

            @media (min-width: 850px) and (max-width: 1150px) {
                padding-bottom: 102px;
            }

            @media (max-width: 849.99px) {
                padding: 3rem 2.5rem;
            }
        }
    }

    background-color: var(--hero-bg);
    background-image: url("../img/swirl.svg");
    background-repeat: no-repeat;
    background-size: calc(100% + 1300px);
    background-position: right -20% top 30%;

    @media (max-width: 849.99px) {
        background-position: right 5% top -400px;
        background-size: 3354px 1154px;
    }

    &__container {
        @media (min-width: 1150px) {
            display: flex;
            justify-content: space-between;
        }
    }

    .col-lg-7 {
        @media (min-width: 1150px) {
            padding-right: 15px;
            flex: 0 0 58.3333333333%;
            max-width: 58.3333333333%;
        }

        
    }

    .col-lg-4 {
        @media (min-width: 1150px) {
            flex: 0 0 33.3333333333%;
        }
    }

    .container {
        padding: 6rem 2.5rem 10rem 2.5rem;

        @media (max-width: 849.99px) {
            padding: 3rem 2.5rem;
        }

        @media (min-width: 576px) {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
        }

        @media (max-width: 575.99px) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    h1, p, h2 {
        color: var(--color-white);
    }

    .btn-login,
    .btn-selected-number {
        @media (min-width: 850px) {
            display: none;
        }
    }

    &__title {
        margin-top: 0;
        margin-bottom: .5rem;
    }

    &__sub-title {
        font-size: 28px;
        font-weight: 100;
        margin-bottom: 2rem;
        margin-top: 0;

        @media (max-width: 849.99px) {
            font-size: 26px;
            font-weight: 400;
        }
    }

    &__description {
        font-size: 18px;
        letter-spacing: .36px;
        margin-bottom: 1.5rem;
        margin-top: 0;
    }

    &__logo {
        text-align: right;
        max-width: 207px;

        @media (max-width: 849.99px) {
            display: none;
        }

        img {
            height: auto;
            width: 100%;
        }
    }

    .btn-arrow {
        margin-bottom: 2rem;
    }

    .btn-login {
        padding:1rem;
    }

    .btn-selected-number {
        margin-top: 2.5rem;
        margin-bottom: 0;
    }

    &__right {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-self: flex-start;
        position: relative;
        text-align: left;
        max-width: 450px;

        @media (max-width: 1149.99px) {
            margin: 52px auto 0;
        }

            @media (min-width: 576px) {
            padding: 2rem;
            border: 1px solid var(--color-white);

            &::after, 
            &::before {
                position: absolute;
                content: "";
                right: -31px;
                background-color: #fecc08;
            }

            &::before {
                bottom: 100%;
                height: 31px;
                width: 206px;
            }

            &::after {
                top: -31px;
                height: 149px;
                width: 31px;
            }
        }

        h3 {
            font-size: 30px;
            font-weight: 100;   
            color: var(--color-white);
            margin-top: 0;
            margin-bottom: 0;

            @media (max-width: 849.99px) {
                font-size: 26px;
                font-weight: 400;
            }
        }

        a {
            color: var(--color-white);
            font-weight: 700;
            font-size: .9rem;
            padding: .5rem .8rem;
            background-color: hsla(0deg, 0%, 100%, .3);
            border-radius: 4px;
            text-align: left;
            text-decoration: none;

            &:hover {
                background-color: hsla(0deg, 0%, 100%, .15);

                span {
                    text-decoration: none;
                }
            }

            span {
                color: #fecc08;
                margin: 0 .4em;
                text-decoration: underline;
                word-break: break-all;
            }
        }
    }
}