.tabs-container {
    width: 100%;
}

.tabs-navigation {
    --tab-color: #959595;
    --tab-active-color: #62bb46;
    display: flex;
    gap: 8%;
    margin-bottom: 2.5rem;
    border-bottom: 1px solid  rgba(0,0,0,.1);
}

.tab-button {
    color: var(--tab-color);
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease;
    text-align: left;
    text-transform: initial;
    margin-bottom: 0;

    &:focus {
        outline: none;
    }

    &.active {
        border-bottom-color: var(--tab-active-color);
        color: var(--tab-active-color);
        font-weight: 700;
    }
}


.tab-select-mobile {
    --border-color: #ced4da;
    --text-color: #777879;
    display: none;
    width: 100%;
    padding: .5rem 1.75rem .5rem .75rem;
    margin-bottom: 2rem;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    color: var(--text-color);
    background-image: url('../img/select-arrow.svg');
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 8px 10px;
    appearance: none;

    &::-ms-expand {
        display: none;
    }

    &:focus {
        outline: none;
    }
}

/* Tab content styles */
.tab-pane {
    --text-color: #717171;
    display: none;

    ul {
        list-style: none;
        padding: 0;
    }

    p {
        color: var(--text-color);
    }

    .list-item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 16px;

        img {
            width: 36px;
            height: auto;
            margin-right: 36px;
        }

        &__title {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 0;
            margin-top: 0;
        
            @media (max-width: 575.98px) {
                font-size: 1rem;
            }
        }

        p {
            margin-top: 0;
        }
    }
}

.tab-pane.active {
    display: block;
}

/* Mobile styles */
@media (max-width: 575.98px) {
    .tabs-navigation {
        display: none;
    }

    .tab-select-mobile {
        display: block;
    }
}
