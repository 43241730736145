/*
==========================================================================
   #Font face
========================================================================== */
@font-face {
    font-family: PKFGlobalSans;
    font-weight: 400;
    font-style: normal;
    src: local("PKFGlobalSans"),
        url("../fonts/PKFGlobalSans-Regular.otf") format("opentype"),
        url("../fonts/PKFGlobalSans-Regular.ttf") format("truetype"),
        url("../fonts/PKFGlobalSans-Regular.woff2") format("woff2"),
        url("../fonts/PKFGlobalSans-Regular.woff") format("woff");
}

@font-face {
    font-family: PKFGlobalSans;
    font-weight: 700;
    font-style: normal;
    src: local("PKFGlobalSans"),
        url("../fonts/PKFGlobalSans-Bold.otf") format("opentype"),
        url("../fonts/PKFGlobalSans-Bold.ttf") format("truetype"),
        url("../fonts/PKFGlobalSans-Bold.woff2") format("woff2"),
        url("../fonts/PKFGlobalSans-Bold.woff") format("woff");
}
