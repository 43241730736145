.message-block {
    --color-logo-border: #003594;
    
    background-color: var(--message-bg);
    padding: 6rem 1rem;

    @media (min-width: 576px) {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }

    &__container {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;

        @media (min-width: 850px) {
            max-width: 83.3333333333%;
        }

        @media (min-width: 1150px) {
                max-width: 66.6666666667%;
        }
    }

    &__title {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 28px;
        font-weight: 100;
        color: var(--color-title);

        @media (max-width: 849.98px) {
            font-size: 24px;
            font-weight: 400;
        }
    }

    &__content {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding-top: 2.5rem;

        @media (min-width: 850px) {
            flex-direction: row;
            padding-top: 3rem;
        }
    }

    &__logo {
        max-width: 270px;
        height: auto;
        border: 1px solid var(--color-logo-border);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
        margin-right: 1rem;
        flex-shrink: 0;

        @media (max-width: 849.98px) {

            margin: 0 auto 1.5rem;
        }

        &::after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }

        img {
            max-width: calc(100% - 52px);
            max-height: calc(100% - 40px);
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    &__description p {
        margin-top: 0;
    }
}